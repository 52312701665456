import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        OFFLINE_PAYMENT,
        CUSTOM_INVOICE_ID
        } = URLS;
    return {
        postOfflinePayment: (body = {}, method = 'post') =>
            performRequest(method ? method : 'post', OFFLINE_PAYMENT.replace(':_id', body._id), body),
        getInvoice: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', CUSTOM_INVOICE_ID.replace(':_id', body._id)),
    }
}




