import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { GET_SURCHARGES_DETAILS,
        GET_SURCHARGES_CALENDER_DETAILS } = URLS;
    return {
        getSurchargesDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_SURCHARGES_DETAILS, body, 'user'),
        getSurchargeCalenderDetails: (body = {}, method = 'get') =>
            performRequest(method ? method : 'get', GET_SURCHARGES_CALENDER_DETAILS, body, 'user'),

    }
}




