import { performRequest } from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const { 
        GET_FEEDBACK_OEUSTION,
        UPDATE_FEEDBACK_CUSTOMER,
        GET_CUSTOMER_FEEDBACK
     } = URLS;
    return {
        getfeedbackQeustion: (body = {}, method = 'get', query) =>
            performRequest(method ? method : 'get', GET_FEEDBACK_OEUSTION, body),
        updateFeedBack: (body={}, method='POST',id) =>
            performRequest(method ? method:'POST', UPDATE_FEEDBACK_CUSTOMER, body, 'user'),
        getCustomerFeedBack: (body = {}, method = 'get') =>
            performRequest(method ? method : 'post', GET_CUSTOMER_FEEDBACK, body, 'user'),
        getIndvCustomerFeedBack: (body = {}, method = 'get',id) =>
            performRequest(method ? method : 'post', GET_CUSTOMER_FEEDBACK+`/${id}`, body, 'user'),
    }
}




