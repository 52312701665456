import mock from "../mock"
export let amenitiesList = [
  {
    id: 1,
    active_status: true,
    name: "Wifi",
    icon_path: "/img/svg/wifi-outlined.svg",    
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 2,  
    active_status: true,
    name: "Mini Fridge",
    icon_path: "/img/svg/fridge.svg",    
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 3,
    active_status: true,
    name: "Telephone",
    icon_path: "/img/svg/phone-dial-outline.svg",    
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 4,
    active_status: true,
    name: "Sofa",
    icon_path: "/img/svg/sofa-single-outline.svg",    
    desc: "Lorem ipsum doler sit amith"
  },
  {
    id: 5,
    active_status: true,
    name: "Snorkel",
    icon_path: "/img/svg/diving-snorkel.svg",    
    desc: "Lorem ipsum doler sit amith"
  },  
]

// GET DATA
mock.onGet("/api/amenitiesList/initial-data").reply(response => {
  return [200, amenitiesList]
})

mock.onGet("/api/amenitiesList/data").reply(response => {
  let { page, perPage } = response  
  let totalPages = Math.ceil(amenitiesList.length / perPage)
  if (page !== undefined && perPage !== undefined) {
    let calculatedPage = (page - 1) * perPage    
    let calculatedPerPage = page * perPage
    return [
      200,
      { data: amenitiesList.slice(calculatedPage, calculatedPerPage), totalPages }
    ]
  } else {
    return [
      200,
      { data: amenitiesList.slice(0, 10), totalPages: Math.ceil(amenitiesList.length / 10) }
    ]
  }
})

// UPDATE DATA
mock.onPost("/api/amenitiesList/update-data").reply(request => {
  //let data = JSON.parse(request.data).obj
  amenitiesList.map(item => {
    return item    
  })
  return [200]
})

// Add DATA
mock.onPost("/api/amenitiesList/add-data").reply(request => {
  let data = JSON.parse(request.data).obj
  let highestId = Math.max.apply(
    Math,
    amenitiesList.map(i => i.id)
  )
  amenitiesList.unshift({
    ...data,
    id: highestId + 1,
    
  })
  return [200]
})

// DELETE DATA
mock.onPost("/api/amenitiesList/delete-data").reply(request => {
  let data = JSON.parse(request.data).obj
  let index = amenitiesList.findIndex(item => item.id === data.id)
  amenitiesList.splice(index, 1)
  return [200]
})

// DELETE SELECTED DATA
mock.onPost("/api/amenitiesList/delete-selected").reply(request => {
  let data = JSON.parse(request.data).arr
  let reducedArray
  ;[amenitiesList, data].reduce((a, b) => {
    let c = b.map(j => j.id)
    return (reducedArray = a.filter(i => !c.includes(i.id)))
  })
  amenitiesList = reducedArray
  return [200]
})
