import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const {GET_RESERVATION_INFO,
           GET_PRE_RESERVATION_INFO,
           SET_RESERVATION_PAYMENT_STATUS,
           GET_PAYMENT_STATUS,
           UPDATE_NOTES,
           GET_WALLET,
           DELETE_TRANS,
           ADD_NOTES,
           PAY_PROMO_PACKAGE_FROM_WALLET,
           PAY_EVENT_FROM_WALLET,
           BLOCK_PRMO_CODE,
           BOOK_PRMO_CODE,
           WALLET_PRMOCODE
         } = URLS;
    return {
         walletPrmoCode:(body={}, method='POST') =>
         performRequest( method ? method:'POST', WALLET_PRMOCODE, body, 'userPortal'),
         blockPrmoCode: (body={}, method='POST',id) =>
         performRequest( method ? method:'POST', BLOCK_PRMO_CODE, body, 'userPortal'),
         bookPrmoCode: (body={}, method='POST',id) =>
         performRequest( method ? method:'POST', BOOK_PRMO_CODE, body, 'userPortal'),
         getReservationDetails: (body={}, method='get',id) =>
         performRequest( method ? method:'get', GET_RESERVATION_INFO.replace(":_id", id), body),
         getPreReservationDetails: (body={}, method='get',id) =>
         performRequest( method ? method:'get', GET_PRE_RESERVATION_INFO.replace(":_id", id), body, 'userPortal'),
         changePaymentStatus: (body={}, method='put',id) =>
         performRequest( method ? method:'put', SET_RESERVATION_PAYMENT_STATUS, body,'',id ),
         getPaymentStatus: (body={}, method='get') =>
         performRequest( method ? method:'get', GET_PAYMENT_STATUS.replace(":_id", body._id), body, 'userPortal' ),
         updateNotes: (body={}, method='put') =>
         performRequest( method ? method:'put', UPDATE_NOTES.replace(":_id", body._id), body, 'userPortal' ),
         getWallet:(body={}, method='get') =>
         performRequest( method ? method:'get', GET_WALLET, body, 'userPortal' ),
         payFromWallet:(body={}, method='post') =>
         performRequest( method ? method:'post', GET_WALLET, body, 'userPortal' ),
         payFromWalletForPackage:(body={}, method='post') =>
         performRequest( method ? method:'post', PAY_PROMO_PACKAGE_FROM_WALLET, body, 'userPortal' ),
         payFromWalletForEvent:(body={}, method='post') =>
         performRequest( method ? method:'post', PAY_EVENT_FROM_WALLET, body, 'userPortal' ),
         deleteTrans:(body={}, method='DELETE') =>
         performRequest( method ? method:'DELETE', DELETE_TRANS.replace(":tansId", body.tansId).replace(":_id", body._id), body, 'userPortal' ),
         addNotes:(body={}, method='put') =>
         performRequest( method ? method:'put', ADD_NOTES.replace(":transId", body.tansId).replace(":_id", body._id), body, 'userPortal' ),
     }
}