import React, {useEffect,useState} from "react"
import { Navbar } from "reactstrap"
import { connect } from "react-redux"
import classnames from "classnames"
import { useAuth0 } from "../../../authServices/auth0/auth0Service"
import {logo} from '../../../configs/resort'

import {
  logoutWithJWT,
  logoutWithFirebase
} from "../../../redux/actions/auth/loginActions"
import NavbarBookmarks from "./NavbarBookmarks"
import NavbarUser from "./NavbarUser"
import userImg from "../../../images/user-avatar-filled.svg"
import Sidebar from "../../components/menu/horizontal-menu/HorizontalMenu"
//import userImg from "../../../assets/img/portrait/small/avatar-s-11.jpg"
import { profileService } from "../../../service";
const img = require('../../../images/images/undraw_profile_pic.svg')

const UserName = props => {
  let data =localStorage.getItem("accessToken")
  let userData=JSON.parse(data)
  let username=userData.name

  // let username = ""
  // if (props.userdata !== undefined) {
  //   username = props.userdata.name
  // } else if (props.user.login.values !== undefined) {
  //   username = props.user.login.values.name
  //   if (
  //     props.user.login.values.loggedInWith !== undefined &&
  //     props.user.login.values.loggedInWith === "jwt"
  //   ) {
  //     username = props.user.login.values.loggedInUser.name
  //   }
  // } else {
  //   username = "John Doe"
  // }

  return username
}
const ThemeNavbar = props => {
  const { user } = useAuth0()
  const colorsArr = [ "primary", "danger", "success", "info", "warning", "dark"]
  const navbarTypes = ["floating" , "static" , "sticky" , "hidden"]
  const [file, setFile] = useState({})
  useEffect(() => {
     
    const fetchData = async() => {
      try {
          const profile = await profileService().getProfileDetails({},'get',JSON.parse(localStorage.getItem('accessToken'))._id)
          if(profile){
            setFile(profile.data.data?.profilePicture)
          }
      } catch (error) {
        
      }
    }
    fetchData()
  }, [])
 
  return (
    <React.Fragment>
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <Navbar
        className={classnames(
          "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
          {
            "navbar-light": props.navbarColor === "default" || !colorsArr.includes(props.navbarColor),
            "navbar-dark": colorsArr.includes(props.navbarColor),
            "bg-primary":
              props.navbarColor === "primary" && props.navbarType !== "static",
            "bg-danger":
              props.navbarColor === "danger" && props.navbarType !== "static",
            "bg-success":
              props.navbarColor === "success" && props.navbarType !== "static",
            "bg-info":
              props.navbarColor === "info" && props.navbarType !== "static",
            "bg-warning":
              props.navbarColor === "warning" && props.navbarType !== "static",
            "bg-dark":
              props.navbarColor === "dark" && props.navbarType !== "static",
            "d-none": props.navbarType === "hidden" && !props.horizontal,
            "floating-nav":
              (props.navbarType === "floating" && !props.horizontal) || (!navbarTypes.includes(props.navbarType) && !props.horizontal),
            "navbar-static-top":
              props.navbarType === "static" && !props.horizontal,
            "fixed-top": props.navbarType === "sticky" || props.horizontal,
            "scrolling": props.horizontal && props.scrolling

          }
        )}
      >
        <div className="navbar-wrapper">
          <div className="navbar-container content">
            <div
              className="navbar-collapse d-flex justify-content-between align-items-center"
              id="navbar-mobile"
            > 
              <div className="logo-menu d-flex">
                {props.horizontal ? (
                  <div className="logo d-flex">
                      <div className= {process.env.REACT_APP_PRODUCTION_MODE === "STAGE" ? "brand-logo-stage mr-50" : "brand-logo mr-50"}></div>
                  </div>
                ) : null}
               
                {/* Horizondal Menu in Desktop View */}
                <Sidebar
                  // toggleSidebarMenu={this.toggleSidebarMenu}
                  // sidebarState={this.state.sidebarState}
                  // sidebarHover={this.sidebarMenuHover}
                  // sidebarVisibility={this.handleSidebarVisibility}
                  // visibilityState={this.state.sidebarHidden}
                  // activePath={this.props.match.path}
                  // currentLang={this.state.currentLang}
                  // activeTheme={customizerProps.menuTheme}
                  // collapsed={this.state.collapsedContent}
                  // menuOpen={this.state.menuOpen}
                  // navbarType={customizerProps.navbarType}
                />

                <div className="bookmark-wrapper">
                  <NavbarBookmarks
                    sidebarVisibility={props.sidebarVisibility}
                    handleAppOverlay={props.handleAppOverlay}
                  />
                </div> 
              </div>

              <NavbarUser
                handleAppOverlay={props.handleAppOverlay}
                changeCurrentLang={props.changeCurrentLang}
                userName={<UserName userdata={user} {...props} />}
                userImg={
                  file && file.hasOwnProperty('url')?file.url:img
                }
                loggedInWith={
                  props.user !== undefined &&
                  props.user.login.values !== undefined
                    ? props.user.login.values.loggedInWith
                    : null
                }
                logoutWithJWT={props.logoutWithJWT}
                logoutWithFirebase={props.logoutWithFirebase}
              />
            </div>
          </div>
        </div>
      </Navbar>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.auth
  }
}

export default connect(mapStateToProps, {
  logoutWithJWT,
  logoutWithFirebase,
  useAuth0
})(ThemeNavbar)
