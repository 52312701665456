import { performRequest} from './api-handler';
import { URLS } from '../utility/urls'

export default () => {
    const {
        GET_CUSTOMER_DATA,
        UPDATE_PASSWARD,
        GET_SOCIAL_SHARE_DETAILS,
        GET_SHAREMODAL_DETAILS,
        AGENT_INFO
         } = URLS;
    return {
         getProfileDetails: (body={}, method='get',id) =>
         performRequest( method ? method:'get', GET_CUSTOMER_DATA.replace(":_id", id), body,'user'),
         updateProfileDetails: (body={}, method='put',id) =>
         performRequest( method ? method:'put', GET_CUSTOMER_DATA.replace(":_id", id), body,'user'),
         updatePasswordDetails: (body={}, method='post',id) =>
         performRequest( method ? method:'post', UPDATE_PASSWARD, body,'user'),
         getSocialShareDetails: (body={}, method='get',id) =>
            performRequest( method ? method:'get',  GET_SOCIAL_SHARE_DETAILS,),
        getshareModalDetails: (body={}, method='get',id) =>
            performRequest( method ? method:'get',  GET_SHAREMODAL_DETAILS,{},'user'),
        updateAgentBankDetails:(body = {}, method = 'put') =>
            performRequest(method ? method : 'put', AGENT_INFO, body, "agent"),
    }
}